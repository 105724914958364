// src/components/Tracking.tsx
import React, { useEffect } from 'react';
import { UserPreferenceProvider } from "../contexts/UserPreferencesContext";
import { DevicesProvider } from "../contexts/GpsDeviceContext";
import { StatusCountsProvider } from "../contexts/StatusCountContext";
import { FilterProvider } from "../contexts/FilterDevicesContext";
import { WebSocketProvider } from "../contexts/WebsocketContext";
import { TourProvider, useTour } from "../contexts/TourContext";
import DevicesList from "./devices/DevicesList";
import TopBar from "./topbar/TopBar";
import MapComponent from "./map/Map";
import { NotificationProvider } from "../contexts/NotificationContext";
import Notifications from "./notifications/Notifications";
import { Tour, ConfigProvider } from "antd";
import type { TourProps } from 'antd';
import esES from 'antd/locale/es_ES';
import { APIProvider } from "@vis.gl/react-google-maps";
import AlertModal from './alert_modal/AlertModal';
import { AlertProvider } from '../contexts/AlertContext';

esES.Tour = {
  Next: 'Siguiente',
  Previous: 'Anterior',
  Finish: 'Finalizar',
};


const Content = () => {
  const { ref1, ref2, setOpen } = useTour();
  

  const steps: TourProps['steps'] = [
    {
      title: 'Barra de herramientas',
      description: 'texto de la barra de herramientas',
      target: () => ref1.current,
    },
    {
      title: 'Filtrar unidades por estado de motor',
      description: 'Puedes filtrar por estado de motor',
      target: () => ref2.current
    }
  ];

  return (
    <div className="app-container">
      <TopBar />
      <DevicesList />
      <MapComponent />
      <Notifications />
      <Tour open={false} onClose={() => setOpen(false)} steps={steps} className="tour" />
      <AlertModal />
    </div>
  );
}
const Tracking: React.FC<any> = (props) => {

  const [preferences, setPreferences] = React.useState(props);

  useEffect(() => {
    setPreferences(props);
  }, [props]);

  console.log("MAPKEY", process.env.REACT_APP_GOOGLE_MAPS_API_KEY); 
  
  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
      <AlertProvider>
        <UserPreferenceProvider initialPreferences={preferences}>
          <NotificationProvider>
            <DevicesProvider initialGroups={preferences.groups}>
              <StatusCountsProvider>
                <FilterProvider>
                  <WebSocketProvider>
                    <TourProvider>
                      <ConfigProvider locale={esES}>
                        <Content />
                      </ConfigProvider>
                    </TourProvider>
                  </WebSocketProvider>
                </FilterProvider>
              </StatusCountsProvider>
            </DevicesProvider>
          </NotificationProvider>
        </UserPreferenceProvider>
      </AlertProvider>
    </APIProvider>
  );
};

export default Tracking;
