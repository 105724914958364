import React, { useState, useEffect, useRef } from 'react';
import { GpsDeviceType } from "../../types/device";
import { Battery, LastConnection, Satellites, Speed } from "./DeviceHelpers";
import { engineStatuColors } from "../../constants/devices";
import { useUserPreferences } from "../../contexts/UserPreferencesContext";
import { useDevices } from '../../contexts/GpsDeviceContext';
import Icon from '../Icon';
import DeviceInformation from './generalinfo/DeviceInformation';
import Driver from './driver/Driver';
import Commands from './commands/Commands';
import Trailer from './trailer/Trailer';
import { APP_URL } from '../../utils/constants';
import { useMap } from '@vis.gl/react-google-maps';
import Properties from './properties/Properties';
import RoadEvent from './road_events/RoadEvent';
import Status from './status/Status';
import FootPrint from './footprint/FootPrint';
import QuickView from './quickview/QuickView';
import { getDeviceQuickView } from '../../features/map/Map';
import momentVariant from '../../utils/momentConfig';
import { useAlert } from '../../contexts/AlertContext';

import axios, { CancelTokenSource } from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { set } from 'rsuite/esm/internals/utils/date';


interface DeviceInfoProps {
    info: GpsDeviceType;
}

interface Action {
    icon: string; 
    active: boolean;
    unique?: boolean;
    component?: React.ReactNode;
    visible: boolean;
}

const DeviceInfo: React.FC<DeviceInfoProps> = ({ info }) => {
    const { setDeviceVisibility, activeDevice, setActiveDevice, setQuickViewState } = useDevices();
    const { 
        preferences,
        show_information,
        show_drivers,
        show_actions,
        show_trailers,
        show_events,
        show_device_status,
        show_device_properties,
        show_foot_print_view,
        show_quick_view,
        show_individual_view, 
    } = useUserPreferences();
    const [actions, setActions] = useState<Action[]>([]);
    const [actionActive, setActionActive] = useState<string | boolean>(false);
    const [coordinates, setCoordinates] = useState<Array<{ lat: number, lng: number }>>([]); // Estado para FootPrint
    // const [quick_view_coordinates, setQuickViewCoordinates] = useState<Array<{ lat: number, lng: number }>>([]); // Estado para QuickView
    const map = useMap();
    const cancelTokenRef = useRef<CancelTokenSource | null>(null);
    const { showAlert } = useAlert();

    const getIconPath = (car_image: number) => {
        return preferences?.car_images[car_image] || preferences?.car_images[1];
    };

    const hexString = React.useMemo<string>(
        () => (typeof info.footPrintColor === 'string' ? info.footPrintColor : info.footPrintColor?.toHexString()),
        [info.footPrintColor],
    );

    // const hexStringQuickView = React.useMemo<string>(
    //     () => (typeof info.quickViewColor === 'string' ? info.quickViewColor : info.quickViewColor?.toHexString()),
    //     [info.quickViewColor],
    // );

    // Inicializar acciones solo cuando el dispositivo cambia
    useEffect(() => {
        setActions(prevActions => [
            { icon: 'icon-datasheet', active: prevActions.find(a => a.icon === 'icon-datasheet')?.active || false, unique: true, component: <DeviceInformation device={info} /> , visible: show_information }, 
            { icon: 'users', active: prevActions.find(a => a.icon === 'users')?.active || false, unique: true, component: <Driver device={info} /> , visible: show_drivers },
            { icon: 'console', active: prevActions.find(a => a.icon === 'console')?.active || false, unique: true, component: <Commands device={info} /> , visible: show_actions },
            { icon: 'icon-remolque', active: prevActions.find(a => a.icon === 'icon-remolque')?.active || false, unique: true, component: <Trailer device={info} /> , visible: show_trailers },
            { icon: 'warning', active: prevActions.find(a => a.icon === 'warning')?.active || false, unique: true, component: <RoadEvent device={info} /> , visible: show_events },
            { icon: 'star', active: prevActions.find(a => a.icon === 'star')?.active || false, unique: true, component: <Status device={info} /> , visible: show_device_status },
            { icon: 'filters', active: prevActions.find(a => a.icon === 'filters')?.active || false, unique: true, component: <Properties device={info} /> , visible: show_device_properties },
            { icon: 'footprint', active: prevActions.find(a => a.icon === 'footprint')?.active || false, component: <FootPrint device={info} coordinates={coordinates} />, visible: show_foot_print_view },
            { icon: 'route', active: prevActions.find(a => a.icon === 'route')?.active || false , component: <QuickView device={info} />, visible: show_quick_view },
        ]);
    }, [info, show_information, show_drivers, show_actions, show_trailers, show_events, show_device_status, show_device_properties, show_quick_view, show_foot_print_view]);
    
    // Sincronizar acción activa cuando cambia el dispositivo activo
    useEffect(() => {
        if (activeDevice !== info.imei) {
            setActionActive(false);
            // Desactivar todas las acciones si el dispositivo activo cambia
            setActions(prevActions => 
                prevActions.map(action => ({ ...action, active: false }))
            );
        }
    }, [activeDevice, info.imei]);

    // Manejar la activación de las acciones
    const handleAction = (action: Action) => {
        const newActive = action.icon;

        setActions(prevActions =>
            prevActions.map(a =>{
                if(a.icon === action.icon){
                    return { ...a, active: !a.active }
                } else if (a.icon === 'footprint' && info.footPrintActive || a.icon === 'route' && info.quickViewActive){
                    return a;
                } else{
                    return { ...a, active: false }
                }
            })
        );

        if (actionActive === newActive) {
            setActionActive(false);
            setActiveDevice(null); // Si la acción es desactivada, no hay dispositivo activo
        } else {
            setActionActive(newActive);
            setActiveDevice(info.imei); // Establece el dispositivo activo cuando se activa una acción
        }
    };

    // Mantener la funcionalidad de FootPrint aunque se cambie de acción
    useEffect(() => {
        if (info.footPrintActive) {
            const newCoords = { lat: info.coordinates.lat, lng: info.coordinates.lng };
            setCoordinates(prevCoords => [...prevCoords, newCoords]);
        } else{
            setCoordinates([]);
        }
    }, [info, info.footPrintActive]);


    // useEffect(() => {
    //     const fetchQuickViewCoordinates = async () => {
    //         if (cancelTokenRef.current) {
    //             cancelTokenRef.current.cancel('Request canceled due to new request.');
    //         }
    //         // Creamos un nuevo token de cancelación para la nueva solicitud
    //         cancelTokenRef.current = axios.CancelToken.source();
    
    //         const customMomentVariant = momentVariant();
    //         let startDate = customMomentVariant().startOf('day').format('YYYY-MM-DD HH:mm');
    //         let endDate = customMomentVariant().format('YYYY-MM-DD HH:mm');
    
    //         try {
    //             const resp = await getDeviceQuickView(info.imei, startDate, endDate, cancelTokenRef.current);
    //             if ("events" in resp && resp.events?.length > 0) {
    //                 // Sanitizar las coordenadas
    //                 let sanitizedCoordinates = resp.events
    //                     .filter(event => event.lat !== null && event.lng !== null)
    //                     .map(event => ({ lat: event.lat, lng: event.long, orientation: event.orientation }))
    //                     .filter((coord, index, self) => 
    //                         index === 0 || // Mantener el primer punto
    //                         (coord.lat !== self[index - 1].lat || coord.lng !== self[index - 1].lng)) // Eliminar duplicados
    //                     .filter((coord, index, self) => {
    //                         if (index === 0) return true; // Mantener el primer punto
    //                         const prev = self[index - 1];
    //                         const distance = calculateDistance(prev.lat, prev.lng, coord.lat, coord.lng);
    //                         return distance > 15; // Mantener solo los puntos que están separados por al menos 10 metros
    //                     });
                    
    //                 setQuickViewState(info.imei, info.quickViewActive, info.quickViewColor, sanitizedCoordinates);
    //             } else {
    //                 setQuickViewState(info.imei, info.quickViewActive, info.quickViewColor, []);
    //             }
    //         } catch (error) {
    //             showAlert('error', 'Ocurrió un error al obtener los eventos de la vista rápida, error: ' + error);
    //         }
    //     };
    
    //     if (info.quickViewActive) {
    //         fetchQuickViewCoordinates();
    //     } else {
    //         setQuickViewState(info.imei, info.quickViewActive, info.quickViewColor, []);
    //     }
    
    //     return () => {
    //         if (cancelTokenRef.current) {
    //             cancelTokenRef.current.cancel('Request canceled due to component unmount.');
    //         }
    //     };
    
    // }, [info.quickViewActive]);
    
    // Función para calcular la distancia entre dos coordenadas en metros (fórmula de Haversine)
    const calculateDistance = (lat1, lng1, lat2, lng2) => {
        const toRad = (value) => (value * Math.PI) / 180;
        const R = 6371e3; // Radio de la tierra en metros
        const φ1 = toRad(lat1);
        const φ2 = toRad(lat2);
        const Δφ = toRad(lat2 - lat1);
        const Δλ = toRad(lng2 - lng1);
    
        const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
                  Math.cos(φ1) * Math.cos(φ2) *
                  Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    
        return R * c; // Distancia en metros
    };
    



    // useEffect(() => {
    //     if (quick_view_coordinates.length > 1 && info.quickViewActive && map) {
    //         const polyline = new google.maps.Polyline({
    //             path: quick_view_coordinates,
    //             geodesic: true,
    //             strokeColor: hexStringQuickView || '#1677ff',
    //             strokeOpacity: 0.8,
    //             strokeWeight: 4,
    //         });
    
    //         polyline.setMap(map);
    
    //         // Cleanup de la polilínea y el marcador en cada actualización
    //         return () => {
    //             polyline.setMap(null);
    //         };
    //     }
    // }, [quick_view_coordinates, info.quickViewActive, map, hexStringQuickView]);


    // Dibuja la polilínea en el mapa si hay más de una coordenada
    useEffect(() => {
        if (coordinates.length > 1 && info.footPrintActive && map) {
            const polyline = new google.maps.Polyline({
                path: coordinates,
                geodesic: true,
                strokeColor: hexString || '#1677ff',
                strokeOpacity: 0.8,
                strokeWeight: 4,
            });

            polyline.setMap(map);

            // Cleanup de la polilínea en cada actualización
            return () => {
                polyline.setMap(null);
            };
        }
    }, [coordinates, info.footPrintActive, map]);



    useEffect(() => {
        if (actionActive && map) {
            map.setCenter({ lat: info.coordinates.lat, lng: info.coordinates.lng });
            map.setZoom(16);
        }
    }, [actionActive, map, info]);


    return (
        info &&  
        <div className="device-container">
            <div className='device-info-container'>
                <div className='device-visible'>
                    <input 
                        type="checkbox" 
                        checked={info.visible} 
                        onChange={(e) => {
                            e.stopPropagation();
                            setDeviceVisibility(info.imei, e.target.checked);
                        }} 
                    />
                </div>
                <div className="info">
                    <div 
                        className="circle" 
                        style={{
                            borderWidth: 3,
                            borderStyle: 'solid',
                            borderColor: engineStatuColors[info.engine_status],
                            backgroundImage: `url(${APP_URL}${getIconPath(info.car_image)})`
                        }} 
                    />
                    <div className='name-container'>
                        <p className="name" title={info.name}>{info.name}</p>
                        {info.driver?.name && 
                            <div className="driver-container">
                                <Icon icon='users' size={13} color='#3F3F3F' />
                                <span className='separator'></span> 
                                <span className='driver'>{info.driver.name}</span>
                            </div>
                        }
                    </div>
                </div>
                <div className="details">
                    <Battery percentage={info.battery} />
                    <Satellites satellites={info.satellites} />
                    <LastConnection last_connection={info.last_connection} />
                    <Speed speed={info.speed} unit={preferences?.user_preferences?.units.odometer} show_title={false} />
                </div>
            </div>
            <div className='device-actions'>
                {actions.map((action, index) => {
                    // Validaciones específicas para las acciones 'footprint'
                    const isFootprintActive = action.icon === 'footprint' && info.footPrintActive;
                    const isQuickViewActive = action.icon === 'route' && info.quickViewActive;

                    return (
                        action.visible && 
                        <button 
                            key={index} 
                            className={`action ${action.active ? 'active' : ''}  ${isFootprintActive ? 'active-persisted' : ''} ${isQuickViewActive ? 'active-persisted' : ''}`}
                            onClick={() => handleAction(action)}
                        >
                            <Icon 
                                icon={action.icon} 
                                size={16} 
                                color={(action.active || isFootprintActive || isQuickViewActive) ? '#FFFFFF' : '#3F3F3F'}
                            />
                        </button>
                    );
                })}

                {
                    show_individual_view && <button 
                        className="action" 
                        onClick={() => {
                            //open pop up in new window
                            window.open(
                                // `${APP_URL}/popup?imei=${info.imei}`,
                                `http://localhost:3000/popup?imei=${info.imei}`,
                                info.imei,
                                "width=530,height=333,scrollbars=no,resizable=yes"
                                
                            );
                        }}
                    >
                        <Icon icon='direction' size={16} color='#3F3F3F'  className='rotate' />
                    </button>
                }


            </div>
            {actionActive &&  
                <div className='actions-container'>
                    {actions.find(a => a.icon === actionActive).visible && 
                        actions.find(a => a.icon === actionActive).component
                    }
                </div>
            }
        </div>
    );
};

export default DeviceInfo;
