import React, {useState, useEffect} from "react";
import { GpsDeviceType } from "../../../types/device";
import { useUserPreferences } from "../../../contexts/UserPreferencesContext";
import { getGeocoding } from  "../../../features/geocode/Geocoding"
import { Odometer, Speed, EngineStatus } from "../DeviceHelpers";
import Icon from "../../Icon";
import DOMPurify from "dompurify";
import momentVariant from "../../../utils/momentConfig";


const DeviceInformation: React.FC<{ device: GpsDeviceType }> = ({ device }) => {
    const [address, setAddress] = useState<string | null>(null);
    const [reference, setReference] = useState<string | null>(null);
    const [loadingAddress, setLoadingAddress] = useState<boolean>(true);
    const [loadingReference, setLoadingReference] = useState<boolean>(true);
    const { preferences } = useUserPreferences();
    const customMomentVariant = momentVariant();

    useEffect(() => {
        const getAddress = async () => {
            setLoadingAddress(true);
            try {
                const { coordinates } = device;
                const address = await getGeocoding(coordinates.lat, coordinates.lng);
                setAddress(address.label);
                setReference(`${address.reference} a ${address.distance.toFixed(2)} ${preferences.user_preferences.units.odometer}`);
            } catch (error) {
                setAddress("Error al obtener la dirección");
                setReference("Error al obtener la referencia");
            } finally {
                setLoadingAddress(false);
                setLoadingReference(false);
            }
        };
 
        getAddress();
    }, [device]);

    return (
        <div className="datasheet">
            <div className='info-section'>
                <div className='info flex-row justify-between'>
                    <span className="title">Información:</span>
                    <div className='last-connection'>
                        <Icon icon='clock-1' size={10} color='#3F3F3F' />
                        <span className='separator'></span>
                        <span className='last-connection-info'>{customMomentVariant(device?.last_connection).format('DD/MM/YYYY HH:mm:ss')}</span>
                    </div>
                </div>
                <div className='info flex-column'>
                    <span className='address'>Dirección:</span>
                    <span className='address-info text-bold'>
                        {loadingAddress ? 'Cargando...' : address}
                    </span>
                </div>
                <div className='info flex-column'>
                    <span className='reference'>Referencia:</span>
                    <span className='reference-info text-bold'>
                        {loadingReference ? 'Cargando...' : reference}
                    </span>
                </div>
                <div className='info flex-column'>
                    <span className='coordinates'>Coordenadas:</span>
                    <span className='coordinates-info text-bold'>{`${device?.coordinates?.lat}, ${device?.coordinates?.lng}`}</span>
                </div>
            </div>
            <div className='info-section'>
                <div className='info flex-row justify-between medition-units'>
                    <Odometer odometer={device?.odometer} unit={preferences?.user_preferences.units.odometer} />
                    <Speed speed={device?.speed} unit={preferences?.user_preferences.units.odometer} />
                    <EngineStatus engine_status={device?.engine_status} />
                </div>
            </div>
            {(device?.driver?.name || device?.trailer?.name) && (
                <div className='info-section'>
                    <div className='info flex-row justify-between'>
                        {device?.driver?.name && (
                            <div className='driver-container item-info'>
                                <p className="diver">Conductor:</p>
                                <span className='driver-name text-bold'>{device?.driver?.name}</span>
                            </div>
                        )}
                        {device?.trailer?.name && (
                            <div className='trailers-container item-info'>
                                <p className="trailer">Remolques:</p>
                                <span className='trailer-name text-bold'>{device?.trailer?.name}</span>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {device?.dynamic_params && Object.keys(device?.dynamic_params).length > 0 && (
                <div className='parameters-container'>
                    <div className='title'>Parámetros</div>
                    <div className='parameters two-columns'>
                        {Object.keys(device?.dynamic_params).map((key, index) => (
                            <div key={index} className='parameter'>
                                <p className='parameter-key'>{key}:</p>
                                <span
                                    className='parameter-value'
                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(device?.dynamic_params[key].value) }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default DeviceInformation;