import React, { useCallback, useMemo, useState, useEffect } from "react";
import { GpsDeviceType } from "../../types/device";
import { getMarkerIcon } from "./MarkerIcon";
import type { Marker } from '@googlemaps/markerclusterer';
import { AdvancedMarker, InfoWindow } from '@vis.gl/react-google-maps';
import { useUserPreferences } from "../../contexts/UserPreferencesContext";
import { Odometer, Speed, EngineStatus, Driver, LastConnection } from "../devices/DeviceHelpers";
import { engineStatuColors } from "../../constants/devices";
import { APP_URL } from "../../utils/constants";
import "./CustomMarker.scss";
import { getGeocoding } from "../../features/geocode/Geocoding";

interface CustomMarkerProps {
  device: GpsDeviceType;
  setMarkerRef: (marker: Marker | null, id: number) => void;
}

const CustomMarker: React.FC<CustomMarkerProps> = ({ device, setMarkerRef }) => {
  const { show_device_names, preferences } = useUserPreferences();
  const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(false);
  const [address, setAddress] = useState<string | null>(null);
  const [reference, setReference] = useState<string | null>(null);
  const [loadingAddress, setLoadingAddress] = useState<boolean>(false);
  const [loadingReference, setLoadingReference] = useState<boolean>(false);

  const ref = useCallback((marker: Marker | null) => setMarkerRef(marker, device.id), [setMarkerRef, device.id]);

  useEffect(() => {
    if (isInfoWindowOpen) {
      const getAddress = async () => {
        setLoadingAddress(true);
        try {
          const { coordinates } = device;
          const address = await getGeocoding(coordinates.lat, coordinates.lng);
          setAddress(address.label);
          setReference(`${address.reference} a ${address.distance.toFixed(2)} ${preferences.user_preferences.units.odometer}`);
        } catch (error) {
          setAddress("Error al obtener la dirección");
          setReference("Error al obtener la referencia");
        } finally {
          setLoadingAddress(false);
          setLoadingReference(false);
        }
      };

      getAddress();
    }
  }, [device, isInfoWindowOpen, preferences]);

  const handleMarkerClick = useCallback(() => {
    setIsInfoWindowOpen((prev) => !prev);
  }, []);

  const markerStyle = useMemo<React.CSSProperties>(() => ({
    position: 'absolute',
    transform: 'translate(-50%, -50%) translateY(-50%)',
  }), []);

  const infoWindowStyle: React.CSSProperties = {
    width: "250px",
    fontSize: 10,
  };

  const iconContainerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const labelStyle = useMemo<React.CSSProperties>(() => ({
    position: 'absolute',
    top: '-20px',
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: 'black',
    color: 'white',
    padding: '2px 5px',
    borderRadius: '5px',
    boxShadow: '0 2px 6px rgba(0,0,0,0.3)',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '100px',
    zIndex: 1,
    fontSize: '12px',
  }), []);

  const imageStyle = useMemo<React.CSSProperties>(() => ({
    width: '30px',
    height: '30px',
  }), []);

  const getIconPath = (car_image: number) => {
    return preferences?.car_images[car_image] || preferences?.car_images[1];
  };

  return (
    <>
      <AdvancedMarker 
        position={{ lat: device.coordinates.lat, lng: device.coordinates.lng }} 
        ref={ref} 
        style={markerStyle}
        onClick={handleMarkerClick}
      >
        <div style={iconContainerStyle}>
          {show_device_names && <div style={labelStyle}>{`${device.name}`}</div>}
          <img 
            src={getMarkerIcon(device)} 
            style={{ 
              ...imageStyle, 
              transform: device.engine_status === 'engine_on' ? `rotate(${device.orientation}deg)` : 'none' 
            }} 
            alt="marker-icon" 
          />
        </div>
        <img src="assets/images/marker.png" style={{ width: '15px', height: '15px', padding: "0 10px" }} alt="marker" />
      </AdvancedMarker>

      {isInfoWindowOpen && (
        <InfoWindow 
          pixelOffset={[0, -60]}
          headerDisabled
          position={{ lat: device.coordinates.lat, lng: device.coordinates.lng }}
          onCloseClick={() => setIsInfoWindowOpen(false)}
        >
          <div style={infoWindowStyle}>
            <div className="marker-title-container flex-row align-center">
              <div 
                  className="circle" 
                  style={{
                      borderWidth: 3,
                      borderStyle: 'solid',
                      borderColor: engineStatuColors[device.engine_status],
                      backgroundImage: `url(${APP_URL}${getIconPath(device.car_image)})`
                  }} 
              >
                <div className="orientation-circle">
                <img 
                  src={getMarkerIcon(device)} 
                  style={{ 
                    width: 10,
                    height: 10,
                    transform: device.engine_status === 'engine_on' ? `rotate(${device.orientation}deg)` : 'none' 
                  }} 
                  alt="marker-icon" 
                />
                </div>
              </div>
              <h4 className="marker-title">{device.name}</h4>
            </div>
            <div className="marker-device-params">
              <div className="two-columns mb-1">
                <Driver driver_name={device?.driver?.name || 'Sin conductor'} show_title={device?.driver?.name != ''} />
                <LastConnection last_connection={device.last_connection} show_title={true} />
              </div>
              <div className="three-columns mb-1">
                <Odometer odometer={device?.odometer} unit={preferences?.user_preferences.units.odometer} />   
                <Speed speed={device?.speed} unit={preferences?.user_preferences.units.odometer} />
                <EngineStatus engine_status={device?.engine_status} />
              </div>
            </div>
            <div className="flex-column">
              <span>Dirección:</span> 
              <p className="text-bold mb-1">
                {loadingAddress ? 'Cargando...' : address}
              </p>
            </div>
            <div className="flex-column">
              <span>Referencia:</span> 
              <p className="text-bold mb-1">
                {loadingReference ? 'Cargando...' : reference}
              </p>
            </div>
            <div className="flex-column">
              <span>Coordenadas:</span> 
              <p className="text-bold mb-1">
                {device.coordinates.lat}, {device.coordinates.lng}
              </p>
            </div>
          </div>
        </InfoWindow>
      )}
    </>
  );
};

export default CustomMarker;
